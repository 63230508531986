import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Service from '../../service/Service';
import { useOktaAuth } from "@okta/okta-react";

const SettingTabs = (props) => {

    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    let [adminEmails, setAdminEmails] = useState([]);
    let [userEmails, setUserEmails] = useState([]);
    let [defaultEmails, setDefaultEmails] = useState([]);
    let [defaultAdmins, setDefaultAdmins] = useState([]);
    let [id, setId] = useState(null);

    const addEmail = (type) => {
        if (type === 'admin') setAdminEmails(prevAdminEmails => [...prevAdminEmails, { email: "" }])
        if (type === 'user') setUserEmails(prevUserEmails => [...prevUserEmails, { email: "" }])
    }

    const deleteEmail = (type, val) => {
        if (type === 'admin' && adminEmails.length > 0) { 
            Service.deleteEmail(val.id, accessToken).then(() => {
                setAdminEmails(adminEmails.filter(admin => admin.email !== val.email));
            });
        };
        if (type === 'user' && userEmails.length > 0) {
            Service.deleteEmail(val.id, accessToken).then(() => {
                setUserEmails(userEmails.filter(user => user.email !== val.email))
            });
        };
    }

    const handleEmails = (e, val) => { val.email = e.target.value; }

    const saveSettings = () => {
        let emails = { 'adminEmails': adminEmails, 'userEmails': userEmails };
        if (id) {
            Service.updateEmails(id, emails, accessToken).then((res) => {
                getAllEmails();
            })
        } else {
            Service.saveEmails(emails, accessToken).then((res) => {
                getAllEmails();
            })
        }
    }

    const closeSettings = () => { props.setSettings(false) }

    function getAllEmails() {
        Service.getAllEmails(accessToken).then((res) => {
            if (res.emails !== null) {
                if (res.emails._id) setId(res.emails._id);
                setAdminEmails(res.emails.adminEmails);
                setUserEmails(res.emails.userEmails);
            }
            setDefaultEmails(res.defaultEmails);
            setDefaultAdmins(res.defaultAdmins);
        });
    }

    useEffect(() => {
        if (props.settings) getAllEmails();
    }, [props.settings])

    return (
        <SweetAlert
            title="Settings"
            show={props.settings}
            type={'controlled'}
            confirmBtnText="Save"
            cancelBtnText="Close"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="danger"
            onConfirm={saveSettings}
            onCancel={closeSettings}
            showCancel={true}
            dependencies={[adminEmails, userEmails]}
        >
            {(renderProps: SweetAlertRenderProps) => (
                <Tabs>
                    <TabList>
                        <Tab><b>Email List</b></Tab>
                        <Tab><b>Administrators</b></Tab>
                    </TabList>

                    <TabPanel>
                        <form>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <i className="fa fa-plus" onClick={() => addEmail('user')} title="Add User" style={{ cursor: 'pointer' }}></i>
                                        </th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {defaultEmails?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td></td>
                                                <td style={{ width: '400px' }}>
                                                    <input
                                                        disabled={true}
                                                        type={'text'}
                                                        className="form-control"
                                                        ref={renderProps.setAutoFocusInputRef}
                                                        defaultValue={val.email}
                                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        )
                                    })
                                    }
                                    {userEmails?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td></td>
                                                <td style={{ width: '400px' }}>
                                                    <input
                                                        type={'text'}
                                                        ref={renderProps.setAutoFocusInputRef}
                                                        className="form-control"
                                                        defaultValue={val.email}
                                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        onChange={(e) => handleEmails(e, val)}
                                                        placeholder={'Enter email'}
                                                    />
                                                </td>

                                                <td>
                                                    <i className="fa fa-minus" onClick={() => deleteEmail('user', val)} title="Delete Email" style={{ cursor: 'pointer' }}></i>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form>
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <i className="fa fa-plus" onClick={() => addEmail('admin')} title="Add User" style={{ cursor: 'pointer' }}></i>
                                        </th>
                                        <th>Email</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {defaultAdmins?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td></td>
                                                <td style={{ width: '400px' }}>
                                                    <input
                                                        type={'text'}
                                                        className="form-control"
                                                        disabled={true}
                                                        defaultValue={val.email}
                                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                    />
                                                </td>

                                                <td>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                    {adminEmails?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td></td>
                                                <td style={{ width: '400px' }}>
                                                    <input
                                                        type={'text'}
                                                        ref={renderProps.setAutoFocusInputRef}
                                                        className="form-control"
                                                        defaultValue={val.email}
                                                        onKeyDown={renderProps.onEnterKeyDownConfirm}
                                                        onChange={(e) => handleEmails(e, val)}
                                                        placeholder={'Enter email'}
                                                    />
                                                </td>

                                                <td>
                                                    <i className="fa fa-minus" onClick={() => deleteEmail('admin', val)} title="Delete email" style={{ cursor: 'pointer' }}></i>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </form>
                    </TabPanel>
                </Tabs >
            )}
        </SweetAlert>
    )
}

export default SettingTabs