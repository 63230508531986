import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import altair from '../../images/altair.png';
import bell from '../../images/bell.jpg';
import Notifications from 'react-notifications-menu';
import { useOktaAuth } from '@okta/okta-react';
import SettingTabs from "./SettingTabs";

const Header = (props) => {
    const { oktaAuth, authState } = useOktaAuth();
    const [settings, setSettings] = useState(false);
    const handleSettings = () => { setSettings(true) }
    const signOut = () => {
        oktaAuth.tokenManager.remove(oktaAuth.getAccessToken());
        oktaAuth.signOut({ postLogoutRedirectUri: process.env.REACT_APP_CALLBACK_URL });
        oktaAuth.closeSession()
            .then(() => {
                window.location.reload(); // optional
            })
            .catch(e => {
                if (e.xhr && e.xhr.status === 429) {
                    // Too many requests
                }
            })
    }

    return (
        (authState?.isAuthenticated ? (
            <><Navbar>
                <Navbar.Brand>
                    <img src={altair} alt="" style={{ height: '30px', verticalAlign: 'top' }} />
                    Altair Unlimited Admin Portal
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    {
                        props.isAdmin ? (
                            <Navbar.Text className="pr10">
                                <i style={{ cursor: 'pointer', color: 'white', fontSize: '20px' }}
                                    title='Settings'
                                    onClick={handleSettings}
                                    className="fa fa-cog" aria-hidden="true"></i>
                            </Navbar.Text>) : (<></>)
                    }
                    <Navbar.Text className="pr10" title="Activations">
                        <Notifications
                            data={props.notification}
                            icon={bell}
                            header={{
                                title: 'Activations',
                                option: { text: 'Clear All', onClick: props.clearNotifications }
                            }} />
                    </Navbar.Text>
                    <Navbar.Text className="pr10">
                        <label style={{ color: "white" }}>{props.user.name}</label>
                    </Navbar.Text>
                    <Navbar.Text onClick={signOut} className="pr10">
                        <a href="#login" style={{ color: 'white', textDecoration: 'none' }}>
                            <i style={{ padding: '5px' }} class="fa fa-sign-out" aria-hidden="true"></i>
                            Sign out
                        </a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
                <SettingTabs settings={settings} setSettings={setSettings} />
            </>
        ) : (<></>))
    );
};

export default Header;
