import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';

// importing css
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'react-tabs/style/react-tabs.css';
import 'react-notifications/lib/notifications.css';

import Profile from './components/pages/Profile';
import { useHistory } from 'react-router-dom';
import Home from './components/pages/Home';
import Details from './components/pages/Details';
import { useEffect } from 'react';
import { useState } from 'react';

function App() {
  const history = useHistory();
  const [oktaAuth, setOktaAuth] = useState();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };


  function fetchConfig() {
    fetch(`/publisher_config/config.json`)
    .then((res) => res.json())
    .then((data) => {
      setOktaAuth(new OktaAuth({
        issuer: data.issuer,
        clientId: data.clientId,
        redirectUri: data.callBackUrl
      }));
    });
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <div>
    {
    oktaAuth && 
    <Security oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path='/implicit/callback' component={LoginCallback} />
        <SecureRoute path='/'>
          <Route path='/app/publisher/' component={Profile} />
          <Route path='/app/details/' component={Details} />
          <Route path='/' component={Home} />
        </SecureRoute>
      </Switch>
    </Security>
  }
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>)
}

export default AppWithRouter;
