import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Header from './Header';

function Details() {
    const location = useLocation();
    var details = null;
    var user = null;
    if (location.data !== undefined) {
        details = location.data.subDetails;
        user = location.data.user;
    }
    return (
        <div>
            <Header user={user} />
            <div style={{ paddingTop: "10px" }}>{
                details ? (
                    <div>
                        <div style={{ padding: '10px' }}>
                            <NavLink style={{ textDecoration: 'none' }}
                                to={{
                                    pathname: "/app/publisher/",
                                }}>
                                <i style={{ fontSize: '25px' }} class="fa fa-home" aria-hidden="true"></i>
                            </NavLink>
                        </div>
                        <div style={{ display: "flex", height: "100%", width: "100%" }}>
                            <div className="azure-data-right">
                                <div style={{ padding: "0px 50px" }}>
                                    <div
                                        style={{
                                            paddingBottom: "10px",
                                            borderBottom: "1px solid lightgrey",
                                        }}
                                    >
                                        <h4 className="subscribe-header">Subscription Details</h4>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Company :</label>
                                            <div className="col-sm-8">
                                                {details.company}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Contact Name :</label>
                                            <div className="col-sm-8">{details.customer_name}</div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">
                                                Contact Email :
                                            </label>
                                            <div className="col-sm-8">
                                                {details.customer_email}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">
                                                Customer Name :
                                            </label>
                                            <div className="col-sm-8">{details.customer_name}</div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">
                                                Customer Email :
                                            </label>
                                            <div className="col-sm-8">
                                                {details.customer_email}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">User ID :</label>
                                            <div className="col-sm-8">
                                                {details.user_id}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Subscription Name :</label>
                                            <div className="col-sm-8">
                                                {details.name}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Subscription ID :</label>
                                            <div className="col-sm-8">
                                                {details.subscription_id}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Status :</label>
                                            <div className="col-sm-8">{details.status}</div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">Plan :</label>
                                            <div className="col-sm-8">{details.planId}</div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">
                                                Purchaser Email :
                                            </label>
                                            <div className="col-sm-8">
                                                {details.purchaser_email}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-4 right">
                                                Azure Tenant ID :
                                            </label>
                                            <div className="col-sm-8">
                                                {details.azure_tenant_id}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            padding: "10px 0",
                                            borderBottom: "1px solid lightgrey",
                                        }}
                                    >
                                        <h4 className="subscribe-header">Solver Installation</h4>
                                        <div className="row" style={{ borderBottom: "1px lightgrey" }}>
                                            <div className="col-sm-6 right">
                                                Application and Solvers Part of Default Installation :
                                            </div>
                                            <div className="col-sm-6">
                                                {details.default_installation}
                                            </div>
                                        </div>
                                        <div className="row" style={{ borderBottom: "1px lightgrey" }}>
                                            <div className="col-sm-6 right">
                                                Additional Application Request :
                                            </div>
                                            <div className="col-sm-6">
                                                {details.additional_request}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            padding: "10px 0",
                                            borderBottom: "1px solid lightgrey",
                                        }}
                                    >
                                        <h4 className="subscribe-header">Nodes Configuration</h4>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-6 right">Region :</label>
                                            <div className="col-sm-6">
                                                {details.region}
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ borderBottom: "1px lightgrey" }}
                                        >
                                            <label className="col-sm-6 right">
                                                The Maximum Number Of Nodes :
                                            </label>
                                            <div className="col-sm-6">
                                                {details.num_of_nodes}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ padding: "10px 0" }}>
                                        <h4 className="subscribe-header">Users</h4>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                    </th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {details.users?.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td></td>
                                                            <td style={{ width: '400px' }}>
                                                                {val.name}
                                                            </td>

                                                            <td style={{ width: '400px' }}>
                                                                {val.email}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>Something went wrong...</>
                )
            }
            </div>
        </div >
    )
}

export default Details