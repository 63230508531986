import { httpClient } from "@altair/http";
const hostUrl = window.location.protocol + '//' + window.location.hostname;
async function getAllSubscriptions(token) {
  const response = await httpClient.get(
    hostUrl + `/publisher/subscriptions`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function getAllEmails(token) {
  const response = await httpClient.get(
    hostUrl + `/publisher/emails`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function saveEmails(emails, token) {
  const response = await httpClient.post(
    hostUrl + `/publisher/emails`,
    emails,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function updateEmails(id, emails, token) {
  const response = await httpClient.put(
    hostUrl + `/publisher/emails/${id}`,
    emails,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function deleteEmail(id, token) {
  const response = await httpClient.delete(
    hostUrl + `/publisher/email/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function activateSubscription(subscriptionId, token) {
  const response = await httpClient.get(
    hostUrl + `/publisher/activate/${subscriptionId}`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function refreshSubscription(subscriptionId, token) {
  const response = await httpClient.get(
    hostUrl + `/publisher/refresh/${subscriptionId}`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

async function getAdmins(token) {
  const response = await httpClient.get(
    hostUrl + `/publisher/admins`,
    {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    }
  );
  return response.data;
}

const Service = {
  getAllSubscriptions,
  getAdmins,
  getAllEmails,
  saveEmails,
  updateEmails,
  deleteEmail,
  activateSubscription,
  refreshSubscription
};

export default Service;